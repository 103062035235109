/**
 * @todo - add type defs
 */
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { keysToCamel } from './utils';
class AlgoliaShopperProduct {
    constructor(config) {
        this.origin = config.origin;
    }
    getProducts(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const { ids, day, store, ignoreRange } = args[0].parameters;
            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ids: ids, day: day, store: store, ignoreRange: ignoreRange }),
            };
            const response = yield fetch(`${this.origin}/algolia/products`, reqOptions);
            const responseJSON = yield response.json();
            const convertedResponse = keysToCamel(responseJSON);
            return convertedResponse;
        });
    }
}
export default AlgoliaShopperProduct;
