var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import jwtDecode from 'jwt-decode';
/**
 * Compares the token age against the issued and expiry times. If the token's age is
 * within 60 seconds of its valid time (or exceeds it), we consider the token invalid.
 */
export function isTokenValid(token) {
    if (!token) {
        return false;
    }
    const { exp, iat } = jwtDecode(token.replace('Bearer ', ''));
    const validTimeSeconds = exp - iat - 60;
    const tokenAgeSeconds = Date.now() / 1000 - iat;
    if (validTimeSeconds > tokenAgeSeconds) {
        return true;
    }
    return false;
}
/** Returns token body for SHopperLogin `getToken` endpoint */
export function createGetTokenBody(urlString, slasCallbackEndpoint, codeVerifier) {
    const url = new URL(urlString);
    const urlParams = new URLSearchParams(url.search);
    const usid = urlParams.get('usid');
    const code = urlParams.get('code');
    return {
        grantType: 'authorization_code_pkce',
        code,
        usid,
        codeVerifier: codeVerifier,
        redirectUri: slasCallbackEndpoint,
    };
}
// Ocapi related utilities
const toCamel = (str) => {
    if (str.startsWith('_') || str.startsWith('c_')) {
        return str;
    }
    return str.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};
const isObject = (obj) => {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};
export const keysToCamel = (obj) => {
    if (isObject(obj)) {
        const n = {};
        Object.keys(obj).forEach((k) => {
            n[toCamel(k)] = keysToCamel(obj[k]);
        });
        return n;
    }
    else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return keysToCamel(i);
        });
    }
    return obj;
};
export const camelCaseKeysToUnderscore = (_obj) => {
    if (typeof _obj != 'object')
        return _obj;
    // Copy the incoming object so we dont mutate it
    let obj;
    if (Array.isArray(_obj)) {
        obj = [..._obj];
    }
    else {
        obj = Object.assign({}, _obj);
    }
    for (const oldName in obj) {
        // Camel to underscore (ignore if the key is a SFCC custom attribute)
        const newName = oldName.startsWith('c_')
            ? oldName
            : oldName.replace(/([A-Z])/g, ($1) => {
                return '_' + $1.toLowerCase();
            });
        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.
            if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }
        // Recursion
        if (typeof obj[newName] == 'object' && obj[newName] !== null) {
            obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
        }
    }
    return obj;
};
// This function coverts errors/faults returned from the OCAPI API to the format that is returned from the CAPI
// I added the fault key to make life easier as it's hard to discern a CAPI error
export const convertOcapiFaultToCapiError = (error) => {
    return {
        title: error.message,
        type: error.type,
        detail: error.message,
        // Unique to OCAPI I think
        arguments: error.arguments,
        fault: true,
    };
};
// This function checks required parameters and or body for requests to OCAPI endpoints before sending
export const checkRequiredParameters = (listOfPassedParameters, listOfRequiredParameters) => {
    const isBodyOnlyRequiredParam = listOfRequiredParameters.includes('body') && listOfRequiredParameters.length === 1;
    if (!listOfPassedParameters.parameters && !isBodyOnlyRequiredParam) {
        return {
            title: `Parameters are required for this request`,
            type: `MissingParameters`,
            detail: `Parameters are required for this request`,
        };
    }
    if (listOfRequiredParameters.includes('body') && !listOfPassedParameters.body) {
        return {
            title: `Body is required for this request`,
            type: `MissingBody`,
            detail: `Body is  required for this request`,
        };
    }
    if (isBodyOnlyRequiredParam &&
        listOfRequiredParameters.includes('body') &&
        listOfPassedParameters.body) {
        return undefined;
    }
    let undefinedValues = listOfRequiredParameters.filter((req) => !Object.keys(listOfPassedParameters.parameters).includes(req));
    undefinedValues = undefinedValues.filter((value) => value !== 'body');
    if (undefinedValues.length) {
        return {
            title: `The following parameters were missing from your resquest: ${undefinedValues.toString()}`,
            type: `MissingParameters`,
            detail: `The following parameters were missing from your resquest: ${undefinedValues.toString()}`,
        };
    }
    else {
        return undefined;
    }
};
export const createOcapiFetch = (commerceAPIConfig) => {
    return (endpoint, method, args, methodName, body) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const origin = commerceAPIConfig.origin;
        const { siteId, clientId } = commerceAPIConfig;
        const proxy = `/mobify/proxy/ocapi`;
        const host = `${origin}${proxy}`;
        const headers = Object.assign(Object.assign({}, args[0].headers), { Authorization: (_b = (_a = args === null || args === void 0 ? void 0 : args[0]) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? void 0 : _b['Authorization'], 'Content-Type': 'application/json', 'x-dw-client-id': clientId });
        if (['PUT', 'DELETE', 'PATCH'].includes(method)) {
            headers['x-dw-http-method-override'] = method;
            method = 'POST';
        }
        let response;
        response = yield fetch(`${host}/s/${siteId}/dw/shop/v22_6/${endpoint}`, Object.assign({ method,
            headers, credentials: 'include' }, (body && {
            body: JSON.stringify(body),
        })));
        const httpStatus = response.status;
        // Status check for bridging sessions
        if (!args[1] && response.json && httpStatus !== 204) {
            response = yield response.json();
        }
        const convertedResponse = keysToCamel(response);
        if (convertedResponse.fault) {
            const error = convertOcapiFaultToCapiError(convertedResponse.fault);
            throw new HTTPError(`${httpStatus}`, error.detail);
        }
        else {
            return convertedResponse;
        }
    });
};
// This function derrives the SF Tenant Id from the SF OrgId
export const getTenantId = (orgId) => {
    // Derive tenant id id form org id
    const indexToStartOfTenantId = orgId.indexOf('_', orgId.indexOf('_') + 1);
    const tenantId = orgId.substring(indexToStartOfTenantId + 1);
    return tenantId;
};
/**
 * Indicates if an JSON response from the SDK should be considered an error
 * @param {object} jsonResponse - The response object returned from SDK calls
 * @returns {boolean}
 */
export const isError = (jsonResponse) => {
    if (!jsonResponse) {
        return false;
    }
    const { detail, title, type } = jsonResponse;
    if (detail && title && type) {
        return true;
    }
    return false;
};
/**
 * Converts snake-case strings to space separated or sentence case
 * strings by replacing '_' with a ' '.
 * @param {string} text snake-case text.
 * @returns {string} space separated string.
 */
export const convertSnakeCaseToSentenceCase = (text) => {
    return text.split('_').join(' ');
};
/**
 * No operation function. You can use this
 * empty function when you wish to pass
 * around a function that will do nothing.
 * Usually used as default for event handlers.
 */
export const noop = () => { };
/**
 * Checks if page is viewed from within mobile app and returns OS if applicable
 * @returns {String | Null} Returns the platform name, or null if no matches
 */
export const getMobileDeviceOS = () => {
    var _a;
    try {
        const userAgent = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent;
        const androidUserAgentRegex = /^Iceland(?: QA)*\/([\d.]*) Dalvik\/\d+(.\d)+.*/i;
        const iOSUserAgentRegex = /^Iceland(?: QA)*\/([\d.]*) \(iOS (?:[\d.]*)\)/i;
        // Check if Android OS & app Interface exists
        if (androidUserAgentRegex.test(userAgent) && 'appInterface' in window && window.appInterface != undefined) {
            return "Android";
        }
        // Check if IOS & web kit exists
        else if (iOSUserAgentRegex.test(userAgent) && 'webkit' in window && window.webkit != undefined) {
            return "iOS";
        }
    }
    catch (e) {
        // Not in App, ignore
        return null;
    }
    return null;
};
export const twoFactorHeaders = () => __awaiter(void 0, void 0, void 0, function* () {
    return {
        'x-transit-f': encodeValue(yield new BrowserFingerprint().getFingerprint()),
        'x-transit-t': encodeValue(Intl.DateTimeFormat().resolvedOptions().timeZone),
        'x-transit-s': encodeValue([window.screen.availWidth, window.screen.availHeight].sort().join(",")),
        'x-transit-u': window.navigator.userAgent
    };
});
/**
 * Encode the data provided to a fixed length number (as string). This obfuscates the headers.
 * @returns {string} An encoded value of the input
 */
export const encodeValue = (value) => {
    const seed = 1024;
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < value.length; i++) {
        ch = value.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
};
class BrowserFingerprint {
    constructor() {
        this.context = this.setupContext();
        this.currentTime = this.context.currentTime;
        this.oscillator = this.setupOscillator();
        this.compressor = this.context.createDynamicsCompressor();
        this.setupCompressor();
        this.fingerprint = null;
        this.callback = null;
    }
    setupContext() {
        let audioContext;
        if ('OfflineAudioContext' in window) {
            audioContext = window.OfflineAudioContext;
        }
        else if ('webkitOfflineAudioContext' in window) {
            //Early versions of chrome and safari prefixed this with webkit. But TS cannot find it in it's window type.
            audioContext = window.webkitOfflineAudioContext;
        }
        else {
            throw new Error('OfflineAudioContext is not supported in this browser');
        }
        return new audioContext(1, 44100, 44100);
    }
    setupOscillator() {
        const oscillator = this.context.createOscillator();
        oscillator.type = "triangle";
        oscillator.frequency.setValueAtTime(10000, this.currentTime);
        return oscillator;
    }
    setupCompressor() {
        this.compressor.threshold.setValueAtTime(-50, this.currentTime);
        this.compressor.knee.setValueAtTime(40, this.currentTime);
        this.compressor.ratio.setValueAtTime(12, this.currentTime);
        this.compressor.attack.setValueAtTime(0, this.currentTime);
        this.compressor.release.setValueAtTime(0.25, this.currentTime);
    }
    getFingerprint() {
        return __awaiter(this, void 0, void 0, function* () {
            this.oscillator.connect(this.compressor);
            this.compressor.connect(this.context.destination);
            this.oscillator.start(0);
            const buffer = yield this.context.startRendering();
            let output = 0;
            for (let i = 4500; 5e3 > i; i++) {
                const channelData = buffer.getChannelData(0)[i];
                output += Math.abs(channelData);
            }
            this.compressor.disconnect();
            return output.toString();
        });
    }
}
export class HTTPError extends Error {
    constructor(status, message) {
        super(message);
        this.constructor = HTTPError;
        this.__proto__ = HTTPError.prototype;
        this.message = message;
        this.status = status;
    }
    toString() {
        return `HTTPError ${this.status}: ${this.message}`;
    }
}
export function addSecondsToDate(date, seconds) {
    const dateInMs = date.getTime();
    const msToAdd = seconds * 1000;
    return new Date(dateInMs + msToAdd);
}
