var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { nanoid } from 'nanoid';
import { encode as base64encode } from 'base64-arraybuffer';
// Globals
const isServer = typeof window === 'undefined';
/**
 * Creates Code Verifier use for PKCE auth flow.
 */
export const createCodeVerifier = () => __awaiter(void 0, void 0, void 0, function* () {
    if (isServer) {
        //@ts-ignore
        const randomstring = yield import('randomstring').then((module) => {
            return module.default.generate(128);
        });
        return randomstring;
    }
    return nanoid(128);
});
/**
 * Creates Code Challenge based on Code Verifier
 */
export const generateCodeChallenge = (codeVerifier) => __awaiter(void 0, void 0, void 0, function* () {
    let base64Digest;
    if (isServer) {
        yield import('crypto').then((module) => {
            //@ts-ignore
            const crypto = module.default;
            base64Digest = crypto.createHash('sha256').update(codeVerifier).digest('base64');
        });
    }
    else {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = yield window.crypto.subtle.digest('SHA-256', data);
        base64Digest = base64encode(digest);
    }
    return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
});
