/**
 * @todo - add type defs
 */
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// This class allows integration with OCAPI Orders Resource
// https://documentation.b2c.commercecloud.salesforce.com/DOC2/topic/com.demandware.dochelp/OCAPI/current/shop/Resources/Orders.html
// This implementations coverts CAPI requests to OCAPI requests as there are fundamental differences between the APIS
// One major difference is OCAPI uses snake_case and CAPI uses camelCase for this reaso you will see a utility function in here that convert
// from camelCase to snake_case - camelCaseKeysToUnderscore
// createOcapiFetch is another utility function that returns the response from OCAPI in the fromat returned from CAPI
// Another utility function - checkRequiredParameters is used to check if the parameters or body objects necessary for a call are
// present in the request before making it
import { camelCaseKeysToUnderscore, checkRequiredParameters, createOcapiFetch } from './utils';
class OcapiShopperOrders {
    constructor(config) {
        this.fetch = createOcapiFetch(config);
    }
    createOrder(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { body } = args[0];
            return yield this.fetch('orders', 'POST', args, 'createOrder', camelCaseKeysToUnderscore(body));
        });
    }
    getOrder(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['orderNo'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { orderNo } = args[0].parameters;
            return this.fetch(`orders/${orderNo}`, 'GET', args, 'getOrder');
        });
    }
    updateOrder(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['orderNo'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { orderNo }, body, } = args[0];
            return yield this.fetch(`orders/${orderNo}`, 'PATCH', args, 'updateOrder', camelCaseKeysToUnderscore(body));
        });
    }
}
export default OcapiShopperOrders;
