var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { keysToCamel } from './utils';
import { HttpMethods } from './types/utils';
class EinsteinAPI {
    constructor(commerceAPI) {
        this.commerceAPI = commerceAPI;
        this.config = commerceAPI._config.einsteinConfig;
    }
    einsteinFetch(endpoint, method, body) {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.config;
            const { proxyPath, einsteinId } = config;
            const host = `${this.commerceAPI._config.origin}${proxyPath}`;
            const headers = {
                'Content-Type': 'application/json',
                'x-cq-client-id': einsteinId,
            };
            let response;
            response = yield fetch(`https://api.cquotient.com/v3${endpoint}`, Object.assign({ method: method, headers: headers }, (body && {
                body: JSON.stringify(body),
            })));
            const responseJson = yield response.json();
            return keysToCamel(responseJson);
        });
    }
    /**
     * Tells the Einstein engine when a user views a product.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendViewProduct(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { product } = params, optionalParams = __rest(params, ["product"]);
            const endpoint = `/activities/${this.config.siteId}/viewProduct`;
            const method = HttpMethods.POST;
            const { id, sku = '', altId = '', altIdType = '' } = product;
            const body = Object.assign({ product: {
                    id,
                    sku,
                    altId,
                    altIdType,
                }, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user view search results.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendViewSearch(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { products, searchText } = params, optionalParams = __rest(params, ["products", "searchText"]);
            const endpoint = `/activities/${this.config.siteId}/viewSearch`;
            const method = HttpMethods.POST;
            const body = Object.assign({ searchText,
                products, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user click on a search result.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendClickSearch(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { product, searchText } = params, optionalParams = __rest(params, ["product", "searchText"]);
            const endpoint = `/activities/${this.config.siteId}/clickSearch`;
            const method = HttpMethods.POST;
            const body = Object.assign({ searchText,
                product, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user views Category page with products.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendViewCategory(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { products, category } = params, optionalParams = __rest(params, ["products", "category"]);
            const endpoint = `/activities/${this.config.siteId}/viewCategory`;
            const method = HttpMethods.POST;
            const body = Object.assign({ products,
                category, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user clicks a product item on Category page. NOT clicking on category link in navigation.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendClickCategoryProduct(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { product, category } = params, optionalParams = __rest(params, ["product", "category"]);
            const endpoint = `/activities/${this.config.siteId}/clickCategory`;
            const method = HttpMethods.POST;
            const body = Object.assign({ product,
                category, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user starts a checkout process
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendBeginCheckout(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { products, amount } = params, optionalParams = __rest(params, ["products", "amount"]);
            const endpoint = `/activities/${this.config.siteId}/beginCheckout`;
            const method = HttpMethods.POST;
            const body = Object.assign({ amount,
                products, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user views a set of recommendations
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendViewRecommendations(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { recommenderName, products, __recoUUID } = params, optionalParams = __rest(params, ["recommenderName", "products", "__recoUUID"]);
            const endpoint = `/activities/${this.config.siteId}/viewReco`;
            const method = HttpMethods.POST;
            const body = Object.assign({ recommenderName,
                __recoUUID, products: products, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user clicks on a recommendation
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendClickRecommendations(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { recommenderName, __recoUUID, product } = params, optionalParams = __rest(params, ["recommenderName", "__recoUUID", "product"]);
            const endpoint = `/activities/${this.config.siteId}/clickReco`;
            const method = HttpMethods.POST;
            const { id, sku = '', altId = '', altIdType = '' } = product;
            const body = Object.assign({ recommenderName,
                __recoUUID, product: {
                    id,
                    sku,
                    altId,
                    altIdType,
                }, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Tells the Einstein engine when a user adds an item to their cart.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    sendAddToCart(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { products } = params, optionalParams = __rest(params, ["products"]);
            const endpoint = `/activities/${this.config.siteId}/addToCart`;
            const method = HttpMethods.POST;
            const body = Object.assign({ products: products, userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, optionalParams);
            return this.einsteinFetch(endpoint, method, body);
        });
    }
    /**
     * Get a list of recommenders that can be used in recommendation requests.
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    getRecommenders() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/personalization/recommenders/${this.config.siteId}`;
            const method = HttpMethods.GET;
            return this.einsteinFetch(endpoint, method, null);
        });
    }
    /**
     * Get a set of recommendations
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    getRecommendations(recommenderName, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/personalization/recs/${this.config.siteId}/${recommenderName}`;
            const method = HttpMethods.POST;
            const body = Object.assign({ userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, params);
            // Fetch the recommendations
            const reco = yield this.einsteinFetch(endpoint, method, body);
            return reco;
        });
    }
    /**
     * Get a set of recommendations for a zone
     * https://developer.commercecloud.com/s/api-details/a003k00000UI4hPAAT/commerce-cloud-developer-centereinsteinrecommendations?tabset-888ee=2
     **/
    getZoneRecommendations(zoneName, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/personalization/${this.config.siteId}/zones/${zoneName}/recs`;
            const method = HttpMethods.POST;
            const body = Object.assign({ userId: this.commerceAPI.auth.encUserId
                    ? this.commerceAPI.auth.encUserId
                    : this.commerceAPI.auth.usid, cookieId: this.commerceAPI.auth.encUserId ? this.commerceAPI.auth.usid : '' }, params);
            // Fetch the recommendations
            const reco = yield this.einsteinFetch(endpoint, method, body);
            return reco;
        });
    }
}
export default EinsteinAPI;
