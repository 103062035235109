/**
 * @todo - add type defs
 */
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { checkRequiredParameters, createOcapiFetch } from './utils';
class OcapiShopperStores {
    constructor(config) {
        this.fetch = createOcapiFetch(config);
    }
    getStores(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['latitude', 'longitude', 'distanceUnit', 'maxDistance'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { latitude, longitude, distanceUnit, maxDistance } = args[0].parameters;
            return this.fetch(`stores?latitude=${latitude}&longitude=${longitude}&distance_unit=${distanceUnit}&max_distance=${maxDistance}`, 'GET', args, 'getStores');
        });
    }
    getStore(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['storeId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { storeId } = args[0].parameters;
            return this.fetch(`stores/${storeId}`, 'GET', args, 'getStoreById');
        });
    }
}
export default OcapiShopperStores;
