// @ts-nocheck
import Cookies from 'js-cookie';
class Storage {
    set(key, value, options) { }
    get(key) { }
    remove(key) { }
}
export class CookieStorage extends Storage {
    constructor(...args) {
        super(args);
        this._avaliable = false;
        if (typeof document === 'undefined') {
            console.warn('CookieStorage is not avaliable on the current environment.');
            return;
        }
        this._avaliable = true;
    }
    set(key, value, options) {
        this._avaliable && Cookies.set(key, value, Object.assign({ secure: true }, options));
    }
    get(key) {
        return this._avaliable ? Cookies.get(key) : undefined;
    }
    delete(key) {
        this._avaliable && Cookies.remove(key);
    }
}
export class LocalStorage extends Storage {
    constructor(...args) {
        super(args);
        this._avaliable = false;
        if (typeof window === 'undefined') {
            console.warn('LocalStorage is not avaliable on the current environment.');
            return;
        }
        this._avaliable = true;
    }
    set(key, value) {
        this._avaliable && window.localStorage.setItem(key, value);
    }
    get(key) {
        return this._avaliable ? window.localStorage.getItem(key) : undefined;
    }
    delete(key) {
        this._avaliable && window.localStorage.removeItem(key);
    }
}
