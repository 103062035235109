var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * @todo - add type defs
 */
//@ts-nocheck
import { camelCaseKeysToUnderscore, checkRequiredParameters, createOcapiFetch } from './utils';
class OcapiShopperCustomers {
    constructor(config) {
        this.fetch = createOcapiFetch(config);
    }
    getCustomerBaskets(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['customerId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { parameters: { customerId }, } = args[0];
            return this.fetch(`customers/${customerId}/baskets`, 'GET', args, 'getCustomerBaskets');
        });
    }
    updateCustomer(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['body', 'customerId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { parameters: { customerId }, body, } = args[0];
            return this.fetch(`customers/${customerId}`, 'PATCH', args, 'updateCustomer', camelCaseKeysToUnderscore(body));
        });
    }
    createCustomerAddress(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['body', 'customerId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { parameters: { customerId }, body, } = args[0];
            return this.fetch(`customers/${customerId}/addresses`, 'POST', args, 'createCustomerAddress', camelCaseKeysToUnderscore(body));
        });
    }
    updateCustomerAddress(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['body', 'customerId', 'addressName'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { parameters: { customerId, addressName }, body, } = args[0];
            return this.fetch(`customers/${customerId}/addresses/${addressName}`, 'PATCH', args, 'updateCustomerAddress', camelCaseKeysToUnderscore(body));
        });
    }
    removeCustomerAddress(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['customerId', 'addressName'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { parameters: { customerId, addressName }, } = args[0];
            return this.fetch(`customers/${customerId}/addresses/${addressName}`, 'DELETE', args, 'removeCustomerAddress');
        });
    }
}
export default OcapiShopperCustomers;
