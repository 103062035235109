/**
 * @todo - add type defs
 */
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// This class allows integration with OCAPI Baskets Resource
// https://documentation.b2c.commercecloud.salesforce.com/DOC2/topic/com.demandware.dochelp/OCAPI/current/shop/Resources/Baskets.html
// This implementations coverts CAPI requests to OCAPI requests as there are fundamental differences between the APIS
// One major difference is OCAPI uses snake_case and CAPI uses camelCase for this reaso you will see a utility function in here that convert
// from camelCase to snake_case - camelCaseKeysToUnderscore
// createOcapiFetch is another utility function that returns the response from OCAPI in the fromat returned from CAPI
// Another utility function - checkRequiredParameters is used to check if the parameters or body objects necessary for a call are
// present in the request before making it
import { camelCaseKeysToUnderscore, checkRequiredParameters, createOcapiFetch } from './utils';
class OcapiShopperBaskets {
    constructor(config) {
        this.fetch = createOcapiFetch(config);
    }
    createBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            let { body = {} } = args[0];
            return yield this.fetch('baskets', 'POST', args, 'createBasket', camelCaseKeysToUnderscore(body));
        });
    }
    updateBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}`, 'PATCH', args, 'updateBasket', camelCaseKeysToUnderscore(body));
        });
    }
    getBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { basketId } = args[0].parameters;
            return yield this.fetch(`baskets/${basketId}`, 'GET', args, 'getBasket');
        });
    }
    addItemToBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/items`, 'POST', args, 'addToBasket', camelCaseKeysToUnderscore(body));
        });
    }
    updateItemInBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId, itemId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/items/${itemId}`, 'PATCH', args, 'updateItemInBasket', camelCaseKeysToUnderscore(body));
        });
    }
    removeItemFromBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'itemId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { basketId, itemId } = args[0].parameters;
            return this.fetch(`baskets/${basketId}/items/${itemId}`, 'DELETE', args, 'removeItemFromBasket');
        });
    }
    addPaymentInstrumentToBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/payment_instruments`, 'POST', args, 'addPaymentInstrumentToBasket', camelCaseKeysToUnderscore(body));
        });
    }
    removePaymentInstrumentFromBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'paymentInstrumentId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId, paymentInstrumentId }, } = args[0];
            return this.fetch(`baskets/${basketId}/payment_instruments/${paymentInstrumentId}`, 'DELETE', args, 'removePaymentInstrumentFromBasket');
        });
    }
    getPaymentMethodsForBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { basketId } = args[0].parameters;
            return this.fetch(`baskets/${basketId}/payment_methods`, 'GET', args, 'getPaymentMethodsForShipment');
        });
    }
    getShippingMethodsForShipment(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'shipmentId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { basketId, shipmentId } = args[0].parameters;
            return this.fetch(`baskets/${basketId}/shipments/${shipmentId}/shipping_methods`, 'GET', args, 'getShippingMethodsForShipment');
        });
    }
    updateBillingAddressForBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId, useAsShipping = false }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/billing_address?use_as_shipping=${useAsShipping}`, 'PUT', args, 'updateBillingAddressForBasket', camelCaseKeysToUnderscore(body));
        });
    }
    updateShippingAddressForShipment(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body', 'shipmentId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId, shipmentId, useAsBilling = false }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/shipments/${shipmentId}/shipping_address?use_as_billing=${useAsBilling}`, 'PUT', args, 'updateShippingAddressForShipment', camelCaseKeysToUnderscore(body));
        });
    }
    updateShippingMethodForShipment(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body', 'shipmentId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId, shipmentId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/shipments/${shipmentId}/shipping_method`, 'PUT', args, 'updateShippingMethodForShipment', camelCaseKeysToUnderscore(body));
        });
    }
    updateCustomerForBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/customer`, 'PUT', args, 'updateCustomerForBasket', camelCaseKeysToUnderscore(body));
        });
    }
    deleteBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            const { basketId } = args[0].parameters;
            return this.fetch(`baskets/${basketId}`, 'DELETE', args, 'deleteBasket');
        });
    }
    addCouponToBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'body'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId }, body, } = args[0];
            return this.fetch(`baskets/${basketId}/coupons`, 'POST', args, 'addCouponToBasket', camelCaseKeysToUnderscore(body));
        });
    }
    removeCouponFromBasket(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            const required = ['basketId', 'couponItemId'];
            let requiredParametersError = checkRequiredParameters(args[0], required);
            if (requiredParametersError) {
                return requiredParametersError;
            }
            let { parameters: { basketId, couponItemId }, } = args[0];
            return this.fetch(`baskets/${basketId}/coupons/${couponItemId}`, 'DELETE', args, 'removeCouponFromBasket');
        });
    }
}
export default OcapiShopperBaskets;
