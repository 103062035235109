var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Auth from './auth';
import OcapiShopperBaskets from './ocapi-shopper-baskets';
import OcapiShopperOrders from './ocapi-shopper-orders';
import AlgoliaShopperProduct from './algolia-shopper-product';
import OcapiShopperPromotions from './ocapi-shopper-promotions';
import OcapiShopperSessions from './ocapi-shopper-sessions';
import OcapiShopperCustomers from './ocapi-shopper-customers';
import OcapiShopperStores from './ocapi-shopper-stores';
import OcapiShopperCustomObjects from './ocapi-shopper-custom-objects';
import Einstein from './einstein';
import { isError, isTokenValid } from './utils';
import * as UtilsTypes from './types/utils';
import * as Utils from './utils';
export { AlgoliaShopperProduct };
export { UtilsTypes };
export { Utils };
export * from './types/einstein';
class ApiClassStub {
    constructor() { }
}
export class CommerceAPI {
    constructor(config) {
        var _a;
        const { origin } = config, restConfig = __rest(config, ["origin"]);
        this._config = Object.assign({ origin }, restConfig);
        this.auth = new Auth(this);
        if ((_a = this._config.einsteinConfig) === null || _a === void 0 ? void 0 : _a.einsteinId) {
            this.einstein = new Einstein(this);
        }
        // A mapping of property names to the SDK class constructors we'll be
        // providing instances for.
        const apiConfigs = {
            scapiShopperCustomers: { api: 'ShopperCustomers', sendLocale: false },
            shopperGiftCertificates: { api: 'ShopperGiftCertificates', sendLocale: true },
            shopperLogin: { api: 'ShopperLogin', sendLocale: false },
            shopperProducts: { api: 'ShopperProducts', sendLocale: true },
            algoliaShopperProduct: { api: AlgoliaShopperProduct },
            shopperSearch: { api: 'ShopperSearch', sendLocale: true },
            shopperOrders: { api: OcapiShopperOrders, sendLocale: true },
            shopperBaskets: { api: OcapiShopperBaskets, sendLocale: false },
            scapiShopperBaskets: { api: 'ShopperBaskets', sendLocale: true },
            shopperSessions: { api: OcapiShopperSessions, sendLocale: false },
            shopperPromotions: { api: OcapiShopperPromotions, sendLocale: false },
            shopperCustomers: { api: OcapiShopperCustomers, sendLocale: false },
            shopperStores: { api: OcapiShopperStores, sendLocale: false },
            shopperCustomObjects: { api: OcapiShopperCustomObjects, sendLocale: false },
        };
        // Instantiate the SDK class proxies and create getters from our api mapping.
        // The proxy handlers are called when accessing any of the mapped SDK class
        // proxies, executing various pre-defined hooks for tapping into or modifying
        // the outgoing method parameters and/or incoming SDK responses
        const self = this;
        Object.keys(apiConfigs).forEach((key) => {
            const api = apiConfigs[key].api;
            const ApiClass = typeof api === 'string' ? ApiClassStub : api;
            self[key] = new Proxy(new ApiClass(this._config), {
                get: function (obj, prop) {
                    // Intercept method access on wrapped sdk calls and initiate a fetch to our
                    // custom BFF endpoint/proxy. This allows us to exclude `commerce-sdk-isomorphic`
                    // from the client bundle, saving several KBs and reducing JS parse time.
                    if (obj instanceof ApiClassStub) {
                        return (...args) => __awaiter(this, void 0, void 0, function* () {
                            const { locale, currency, siteId } = self._config;
                            const endpointUrl = `${origin}/api/${siteId}/${api}/${String(prop)}`;
                            // Inject the locale and currency to the API call via its parameters.
                            //
                            // NOTE: The commerce sdk isomorphic will complain if you pass parameters to
                            // it that it doesn't expect, this is why we only add the locale and currency
                            // to some of the API calls.
                            // By default we send the locale param and don't send the currency param.
                            const { sendLocale = true, sendCurrency = false } = apiConfigs[key];
                            const includeGlobalLocale = Array.isArray(sendLocale)
                                ? sendLocale.includes(prop)
                                : !!sendLocale;
                            const includeGlobalCurrency = Array.isArray(sendCurrency)
                                ? sendCurrency.includes(prop)
                                : !!sendCurrency;
                            args[0].parameters = Object.assign(Object.assign(Object.assign({}, (includeGlobalLocale ? { locale } : {})), (includeGlobalCurrency ? { currency } : {})), args[0].parameters);
                            if (args[0].ignoreHooks) {
                                return fetch(endpointUrl, {
                                    method: 'post',
                                    body: JSON.stringify(args),
                                }).then((res) => self.didReceiveResponse(res, args, true));
                            }
                            return self.willSendRequest(String(prop), ...args).then((newArgs) => {
                                // Inject the locale to the API call via it's parameters.
                                //
                                // NOTE: The commerce sdk isomorphic will complain if you pass parameters to
                                // it that it doesn't expect, this is why we only add the local to some of
                                // the API calls.
                                if (apiConfigs[key].canLocalize) {
                                    newArgs[0].parameters = Object.assign(Object.assign({}, newArgs[0].parameters), (locale && { locale }));
                                }
                                return fetch(endpointUrl, {
                                    method: 'post',
                                    body: JSON.stringify(newArgs),
                                }).then((res) => self.didReceiveResponse(res, newArgs, true));
                            });
                        });
                    }
                    if (typeof obj[prop] === 'function') {
                        return (...args) => {
                            const fetchOptions = args[0];
                            const { locale, currency } = self._config;
                            if (fetchOptions.ignoreHooks) {
                                return obj[prop](...args);
                            }
                            // Inject the locale and currency to the API call via its parameters.
                            //
                            // NOTE: The commerce sdk isomorphic will complain if you pass parameters to
                            // it that it doesn't expect, this is why we only add the locale and currency
                            // to some of the API calls.
                            // By default we send the locale param and don't send the currency param.
                            const { sendLocale = true, sendCurrency = false } = apiConfigs[key];
                            const includeGlobalLocale = Array.isArray(sendLocale)
                                ? sendLocale.includes(prop)
                                : !!sendLocale;
                            const includeGlobalCurrency = Array.isArray(sendCurrency)
                                ? sendCurrency.includes(prop)
                                : !!sendCurrency;
                            fetchOptions.parameters = Object.assign(Object.assign(Object.assign({}, (includeGlobalLocale ? { locale } : {})), (includeGlobalCurrency ? { currency } : {})), fetchOptions.parameters);
                            return self.willSendRequest(String(prop), ...args).then((newArgs) => {
                                return obj[prop](...newArgs).then((res) => self.didReceiveResponse(res, newArgs));
                            });
                        };
                    }
                    return obj[prop];
                },
            });
        });
        this.getConfig = this.getConfig.bind(this);
    }
    getConfig() {
        return this._config;
    }
    /**
     * Executed before every proxied method call to the SDK. Provides the method
     * name and arguments. This can be overidden in a subclass to perform any
     * logging or modifications to arguments before the request is sent.
     */
    willSendRequest(methodName, ...params) {
        return __awaiter(this, void 0, void 0, function* () {
            // We never need to modify auth request headers for these methods
            if (methodName === 'authenticateCustomer' ||
                methodName === 'authorizeCustomer' ||
                methodName === 'getAccessToken') {
                return params;
            }
            // If a login promise exists, we don't proceed unless it is resolved.
            const pendingLogin = this.auth.pendingLogin;
            if (pendingLogin) {
                yield pendingLogin;
            }
            // Used on ssr to retrieve a new access token on refresh
            // Updates `this.auth.authToken` with a fresh token
            if (!isTokenValid(this.auth.authToken)) {
                yield this.auth.ocapiAPILogin();
            }
            // Apply the appropriate auth headers and return new options
            const [fetchOptions, ...restParams] = params;
            const newFetchOptions = Object.assign(Object.assign({}, fetchOptions), { headers: Object.assign(Object.assign({}, fetchOptions.headers), { Authorization: this.auth.authToken }) });
            return [newFetchOptions, ...restParams];
        });
    }
    /**
     * Executed when receiving a response from an SDK request. The response data
     * can be mutated or inspected before being passed back to the caller. Should
     * be overidden in a subclass.
     * @param {*} response - The response from the SDK method call.
     * @param {Array} args - Original arguments for the SDK method.
     * @param {boolean} shouldParseJson - Should response be converted to json
     * @returns {*} - The response to be passed back to original caller.
     */
    didReceiveResponse(response, args, shouldParseJson) {
        if (isError(response)) {
            return Object.assign(Object.assign({}, response), { isError: true, message: response.detail });
        }
        // Because we are now proxying SDK calls, we need to ensure that the responses
        // are already 'jsonified' before returning back to the app. This emulates the
        // behavior of the commerce-sdk methods and minimizes change to existing code.
        if (shouldParseJson && parseInt(response.status) !== 204) { //Status property appears to be an int, but parse it just encase
            return response.json();
        }
        return response;
    }
}
